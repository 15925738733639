import React, { memo } from 'react';
import styles from '../../DataExtractionTemplate.module.scss';
import {
  Heading,
  Icon,
  IconList,
  Link,
  Button,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  Text,
} from '../../../../../components/core';
import resultDataStyles from './ResultsDataPanel.module.scss';
import { useReviewFeatures } from 'hooks/reviews/useReviewFeatures';

type dataFormat = {
  name: string;
  tooltip: string;
};

const newContinuousDataFormats: dataFormat[] = [
  { name: 'mean', tooltip: 'Mean' },
  { name: 'SD', tooltip: 'Standard deviation' },
  { name: '95% CI', tooltip: '95% confidence interval' },
  { name: 'SE', tooltip: 'Standard error' },
  { name: 'Total', tooltip: 'Total number of participants' },
  { name: 'median', tooltip: 'Median' },
  { name: 'IQR', tooltip: 'Interquartile range' },
  { name: 'range', tooltip: 'Range' },
  { name: 'MD', tooltip: 'Mean difference' },
  { name: 'SMD', tooltip: 'Standardised mean difference' },
  { name: 'p-value', tooltip: 'p-value' },
  { name: 'Other', tooltip: 'Create data format' },
];

const legacyContinuousDataFormats: dataFormat[] = [
  { name: 'mean', tooltip: 'Mean' },
  { name: 'SD', tooltip: 'Standard deviation' },
  { name: '95% CI', tooltip: '95% confidence interval' },
  { name: 'SE', tooltip: 'Standard error' },
  { name: 'N', tooltip: 'Total number of participants' },
  { name: 'MD', tooltip: 'Mean difference' },
  { name: 'SMD', tooltip: 'Standardised mean difference' },
  { name: 'p-value', tooltip: 'p-value' },
  { name: 'Custom', tooltip: 'Create custom formats in the study' },
];

const legacyDichotomousDataFormats = [
  { name: 'n', tooltip: 'Number of participants with event' },
  { name: '%', tooltip: 'Percentage of participants with event' },
  { name: 'N', tooltip: 'Total number of participants' },
  { name: 'HR', tooltip: 'Hazard ratio' },
  { name: 'log HR', tooltip: 'Log hazard ratio' },
  { name: 'OR', tooltip: 'Odds ratio' },
  { name: 'log OR', tooltip: 'Log odds ratio' },
  { name: 'RD', tooltip: 'Risk difference' },
  { name: 'RR', tooltip: 'Risk ratio' },
  { name: 'log RR', tooltip: 'Log risk ratio' },
  { name: '95% CI', tooltip: '95% confidence interval' },
  { name: 'SE', tooltip: 'Standard error' },
  { name: 'p-value', tooltip: 'p-value' },
  { name: 'Custom', tooltip: 'Create custom formats in the study' },
];

const newDichotomousDataFormats = [
  { name: 'Events', tooltip: 'Number of participants with event' },
  { name: '% Events', tooltip: 'Percentage of participants with event' },
  { name: 'Total', tooltip: 'Total number of participants' },
  { name: 'HR', tooltip: 'Hazard ratio' },
  { name: 'log HR', tooltip: 'Log hazard ratio' },
  { name: 'OR', tooltip: 'Odds ratio' },
  { name: 'log OR', tooltip: 'Log odds ratio' },
  { name: 'RR', tooltip: 'Risk ratio' },
  { name: 'log RR', tooltip: 'Log risk ratio' },
  { name: 'RD', tooltip: 'Risk difference' },
  { name: '95% CI', tooltip: '95% confidence interval' },
  { name: 'SE', tooltip: 'Standard error' },
  { name: 'p-value', tooltip: 'p-value' },
  { name: 'Other', tooltip: 'Create data format' },
];

const chipButton = (format: dataFormat, key: string) => {
  return (
    <Tooltip key={key}>
      <TooltipTrigger>
        <Button
          className={resultDataStyles.chipButton}
          size="xs"
          isNaked={true}
        >
          {format.name}
        </Button>
      </TooltipTrigger>
      <TooltipContent>{format.tooltip}</TooltipContent>
    </Tooltip>
  );
};

type Props = {
  resultsDataAnchor?: string; // NB: ID used for anchor from OutcomeEditor (View list of data formats)
};

const ResultsDataPanel = ({ resultsDataAnchor }: Props) => {
  const { hasSelectableMeasures } = useReviewFeatures();

  const continuousData = hasSelectableMeasures
    ? newContinuousDataFormats
    : legacyContinuousDataFormats;

  const dichotomousData = hasSelectableMeasures
    ? newDichotomousDataFormats
    : legacyDichotomousDataFormats;

  return (
    <section id={resultsDataAnchor} className={styles.section}>
      <div className={resultDataStyles.outcomeTypeSection}>
        <Heading
          type="h5"
          component="h3"
          variant="brand"
          className={resultDataStyles.heading}
        >
          Results data
          <Link
            className={styles.HelpLink}
            onClick={() => ''}
            data-pendo-key="det-results-data"
            aria-label="Get help with results data"
          >
            <Icon icon={IconList.light.faQuestionCircle} />
          </Link>
        </Heading>
        <Text>You can select any of these data formats in each study:</Text>
      </div>
      <div className={resultDataStyles.outcomeTypeSection}>
        <Heading
          type="h6"
          component="h3"
          variant="neutral"
          className={resultDataStyles.heading}
        >
          Continuous
        </Heading>
        {continuousData.map((format, index) =>
          chipButton(format, `continuous_${index + 1}`)
        )}
      </div>
      <div className={resultDataStyles.outcomeTypeSection}>
        <Heading
          type="h6"
          component="h3"
          variant="neutral"
          className={resultDataStyles.heading}
        >
          Dichotomous
        </Heading>
        {dichotomousData.map((format, index) =>
          chipButton(format, `dichotomous_${index + 1}`)
        )}
      </div>
    </section>
  );
};

export default memo(ResultsDataPanel);
